
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ImgHtml from '../../assets/html.webp'
import ImgAnime from '../../assets/anime.webp'
import ImgOptimise from '../../assets/optimise.webp'
import ImgJavascript from '../../assets/javascript.webp'
import ImgApi from '../../assets/api.webp'
import ImgApplication from '../../assets/application.webp'
import { Link } from 'react-router-dom'



function Projets() {
  return (
    <div>
      <Header/>
      <main className='Projet'>
      <h1 className='Projet__title' id="openclassrooms">Projets réalisés</h1>
      <h2 className='Projet__title2'>Projets réalisés dans le cadre de ma formation continue de Développeuse Intégratrice Web chez <a href='https://openclassrooms.com' title='Formations en ligne Openclassrooms' target='_blank' rel='noreferrer'>Openclassrooms</a>&#160;:</h2>
      <nav className='Projet__nav'>
          <Link className='Projet__nav__link' to="../html">
            <p className='Projet__photo'><img src={ImgHtml} alt="Projet Booki - Transformez une maquette en site web html/css"/></p>
            <h2 className='Projet__stitle'>Booki</h2>
            <div className='Projet__description'>Transformez une maquette en site web html/css…</div>
          </Link>
          <Link className='Projet__nav__link' to="../anime">
            <p className='Projet__photo'><img src={ImgAnime} alt="Projet ohmyfood - Dynamisez une page web avec des animations"/></p>
            <h2 className='Projet__stitle'>ohmyfood</h2>
            <div className='Projet__description'>Dynamisez une page web avec des animations…</div>
          </Link>
          <Link className='Projet__nav__link' to="../seo">
            <p className='Projet__photo'><img src={ImgOptimise} alt="Agence La Panthère - Optimisez un site web existant"/></p>
            <h2 className='Projet__stitle'>Agence La Panthère</h2>
            <div className='Projet__description'>Optimisez un site web existant…</div>
          </Link>
          <Link className='Projet__nav__link' to="../javascript">
            <p className='Projet__photo'><img src={ImgJavascript} alt="Projet Kanap - Construisez un site e-commerce en JavaScript"/></p>
            <h2 className='Projet__stitle'>Kanap</h2>
            <div className='Projet__description'>Construisez un site e-commerce en JavaScript…</div>
          </Link>
          <Link className='Projet__nav__link' to="../api">
            <p className='Projet__photo'><img src={ImgApi} alt="Projet Hot Take - Construisez une API sécurisée pour une application d'avis gastronomiques"/></p>
            <h2 className='Projet__stitle'>Hot Take</h2>
            <div className='Projet__description'>Construisez une API sécurisée pour une application d'avis gastronomiques…</div>
          </Link>
          <Link className='Projet__nav__link' to="../application">
            <p className='Projet__photo'><img src={ImgApplication} alt="Projet Kasa - Créez une application web de location immobilière avec React"/></p>
            <h2 className='Projet__stitle'>Kasa</h2>
            <div className='Projet__description'>Créez une application web de location immobilière avec React…</div>
          </Link>
      </nav>
      </main>
      <Footer/>
    </div>
  )
}

export default Projets
