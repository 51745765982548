import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import planImg1 from '../../assets/plan_test_1.webp'
import planImg2 from '../../assets/plan_test_2.webp'



function SliderPlan() {

    const datas = [
        {
            id:1,
            image: `${planImg1}`,
        },
        {
            id:2,
            image: `${planImg2}`,
        },
    ]

  return (
    <Carousel>
        {datas.map(slide => (
            <div key={slide.id}>
                <img src={slide.image} alt="" />
            </div>
        ))}
    </Carousel>
  )
}

export default SliderPlan