import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import rapportPicture1 from '../../assets/rapport_analyse_1.webp'
import rapportPicture2 from '../../assets/rapport_analyse_2.webp'
import rapportPicture3 from '../../assets/rapport_analyse_3.webp'
import rapportPicture4 from '../../assets/rapport_analyse_4.webp'
import rapportPicture5 from '../../assets/rapport_analyse_5.webp'



function Slider() {

    const datas = [
        {
            id:1,
            image: `${rapportPicture1}`,
            title: "Rapport d'analyse",
        },
        {
            id:2,
            image: `${rapportPicture2}`,
            title: "Rapport d'analyse",
        },
        {
            id:3,
            image: `${rapportPicture3}`,
            title: "Rapport d'analyse",
        },
        {
            id:4,
            image: `${rapportPicture4}`,
            title: "Rapport d'analyse",
        },
        {
            id:5,
            image: `${rapportPicture5}`,
            title: "Rapport d'analyse",
        },
    ]

  return (
    <Carousel>
        {datas.map(slide => (
            <div key={slide.id}>
                <img src={slide.image} alt="" />
                <div className="overlay">
                    <h2 className='overlay__title'>{slide.title}</h2>
                </div>
            </div>
        ))}
    </Carousel>
  )
}

export default Slider