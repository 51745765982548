import Header from "../Header"
import Footer from "../Footer"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function ProjetApplication() {
    return (
      <div>
      <Header/>
      <main>
        <div className='Container__projet'>
            <h1 className="Container__projet__title">Créez une application web de location immobilière avec React</h1>
            <div className="Container__projet__texte">
              <p className="Container__projet__alinea">Kasa m'a recruté en tant que développeur front-end en freelance pour développer sa nouvelle plateforme web.</p>
              <p className="Container__projet__alinea">Kasa est dans le métier de la location d’appartements entre particuliers depuis près de 10 ans maintenant.</p>
              <p className="Container__projet__alinea">Avec plus de 500 annonces postées chaque jour, Kasa fait partie des leaders de la location d’appartements entre particuliers en France.</p>
              <p className="Container__projet__alinea">Le site de Kasa a été codé il y a maintenant plus de 10 ans en ASP.NET avec un code legacy important.</p>
              <p className="Container__projet__alinea">Laura, la CTO, a donc lancé une refonte totale pour passer à une stack complète en JavaScript avec NodeJS côté back-end, et React côté front-end.</p>
              <p className="Container__projet__alinea">Kasa en a également profité pour commander de nouvelles maquettes auprès de son designer habituel, qui est en freelance.</p>
              <p className="Container__projet__alinea">J'avais à ma disposition les 20 dernières annonces de logements dans un fichier JSON pour construire le Front qui correspond.</p>
              <p className="Container__projet__alinea">Il fallait :</p>
              <ol className="Container__projet__liste">
                <li className="Container__projet__liste__alinea">Initialiser une application avec Create React App ;</li>
                <li className="Container__projet__liste__alinea">Configurer la navigation entre les pages de l'application avec React Router ;</li>
                <li className="Container__projet__liste__alinea">Développer des éléments de l'interface d'un site web grâce à des composants React.</li>
              </ol>
              <p className="Container__projet__alinea">Le site est disponible ici <a href='https://fontitusse.github.io/Projet_7_Kasa/' title='Booki' target='_blank' rel='noreferrer'>Kasa</a>.</p>
            </div>
            <div className='alinea__center'><Link className='Projet__retour' to="../projets#openclassrooms"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link></div>
        </div>
        </main>
        <Footer/>
        </div>
    )
  }
  
  export default ProjetApplication