import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'

function Sites() {
    return (
    <div>
        <Header/>
        <main>
        <div className='Projet__web' id='sites'>
            <h1 className='Projet__web__title'>Les Sites Web</h1>
            <h2 className='Projet__web__title2'>Rapport du Congrès des Notaires de France</h2>
            <div className='Projet__web__alinea'>Le site du <a href='https://rapport-congresdesnotaires.fr' title="Rapports du Congrès des Notaires de France" target='_blank' rel='noreferrer'>Rapports du Congrès des Notaires de France</a> a été réalisé avec le CMS WordPress&#160;: une CSS personnalisée d'après la maquette livre, intégration des pages HTML, intégration et réalisation du PDF interactif des rapports par année.</div>
            <div className='Projet__web__alinea blancTop'>Les travaux juridiques des Congrès des notaires de France sont ouverts à tous. Leur accès numérique au moyen de ce site est gratuit.</div>
            <div className='Projet__web__alinea'>Ces contributions ont pour vocation de faire progresser la recherche et la pratique juridique.</div>
            <div className='Projet__web__alinea blancTop'>Vous souhaitez me confier la création de votre site&#160;! Contactez Martine fait de l'informatique pour discuter de vos idées et commencer à construire votre présence en ligne.</div>
            <div className='alinea__center'><Link to="../survey#contact" className='button'>Contactez-moi</Link></div>
        </div>
        </main>            
        <Footer/>  
    </div>
    )
}

export default Sites