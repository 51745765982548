import { Link } from 'react-router-dom'
 
function Header() {
    return (
        <header>
            <div className='banner'>
                <h1 className="banner__titre">Martine fait de l'informatique</h1> 
                <h2 className='banner__stitre'>Développeuse Intégratrice&#160;Web</h2>
            </div>
        <nav className='navbar'>
            <Link className='navbar__link' to="/">Accueil</Link>
            <Link className='navbar__link' to="/projetsPro">Projets professionnels</Link>
            <Link className='navbar__link' to="/projets">Projets Openclassrooms</Link>
            <Link className='navbar__link' to="/survey">Contact</Link>
        </nav>
        </header>
    )
}

export default Header