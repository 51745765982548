import Header from '../../components/Header'
import Footer from '../../components/Footer'
import emailjs from "@emailjs/browser";
import React, { useRef } from "react";



function Survey() {
        const form = useRef();
        const sendEmail = (e) => {
          e.preventDefault();
          emailjs.sendForm("service_57fuiqm", "template_ybj0n4s", form.current, "9WF-4bDOgK9FXavK5")
          .then(
            (result) => {
              alert("Votre message a bien été envoyé !");
            },
            (error) => {
              alert("Oops votre message n'a pas pu être envoyé, merci de remplir tous les champs !");
            }
          );
        };
    return (
        <div className='contact'>
        <Header/>
        <main>
        <h1 className='contact__title' id="contact">Contact</h1>
        <div className='container'>
            <form id="formulaire" ref={form} onSubmit={sendEmail}>
              <label>Bonjour, Je suis</label>
              <label for="fullname">Nom<span className='required'>*</span></label>
              <input id="fullname" type="text" name="name" required  minlength="2"/>
              <label for="societe">Société</label>
              <input id="societe" type="text" name="societe" />
              <label for="email">Email<span className='required'>*</span></label>
              <input id="email" type="email" name="email" required/>
              <label for="message">Brief de votre projet<span className='required'>*</span> (Ajoutez autant de détails que nécessaire) :</label>
              <textarea id="message" name="message" required/>
              <button type="submit" className="button">Envoyer</button>
            </form>
        </div>
        </main>
        <Footer/>
        </div>
    )
}


export default Survey