import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from "react-router-dom"
import SiteImg from '../../assets/sites_web.webp'
import FormImg from '../../assets/formations.webp'
import AideImg from '../../assets/aide_outils.webp'



function ProjetsPro() {
    return (
      <div>
        <Header/>
        <main className='Projet'>
            <h1 className='Projet__title'>Projets réalisés</h1>
            <div className='Projet__pro'>
                <nav className='Projet__nav'>
                  <Link className='Projet__nav__link' to="../sites" title="Sites Web">
                   <img src={SiteImg} alt='Sites Web'/>
                  </Link>
                  <Link className='Projet__nav__link' to="../formations" title="Formations">
                  <img src={FormImg} alt='Formations'/>
                  </Link>
                  <Link className='Projet__nav__link' to="../aide" title="Aide aux outils numériques">
                  <img src={AideImg} alt='Aide aux outils numériques'/>
                  </Link>
                </nav>
              </div>
        </main>
        <Footer/>
      </div>
          )
    }

export default ProjetsPro