import { Link } from 'react-router-dom'

function PresHtml() {
    return (
        <div className='presentation' id="presentation">
            <div className='presentation__around'>
            <h1 className='presentation__title'>Bienvenue sur mon site&#160;!</h1>
            <p className='presentation__alinea'>Comme vous l'aurez deviné, je m'appelle MARTINE…</p>
            <p className='presentation__alinea'>En tant que polyvalente Cross-Médias dans une imprimerie, j'utilise SGML, XML pour la saisie et la mise en forme de texte structuré avec une DTD.</p>
            <p className='presentation__alinea'>Je réalise également des livres numériques (ePub) reflowables et nativements accessibles.</p>
            <p className='presentation__alinea'>Les ePubs « textuels » s’appuient sur les technologies Web (XHTML, XML, MathML, SVG, CSS). Toutes les contraintes d’accessibilité pour les ePubs valent pour le Web.</p>
            <p className='presentation__alinea'>SGML, XML, HTML, XHTML et CSS n'ont plus de secret pour moi... Le développement est devenu une passion...</p>
            <p className='presentation__alinea'>La suite était pour moi une évidence, j'ai fait une formation continue et diplômante avec Openclassrooms pour être Développeuse Intégratrice Web.</p>
            <p className='presentation__alinea'>Ma mission est de simplifier le monde de l'informatique pour mes clients. Je comprends que la technologie peut être complexe et parfois intimidante. C'est pourquoi je m'engage à vous accompagner à chaque étape, que ce soit pour un projet de grande envergure ou pour une simple question technique. Je suis là pour vous guider et vous soutenir, afin que vous puissiez tirer pleinement parti de la puissance de l'informatique dans votre quotidien personnel ou professionnel.</p>
            <h2 className='presentation__stitle'>Mes services :</h2>
            <ul className='presentation__lst_puce'>
                <li className='presentation__lst__item'>Intégration de page en <span className='color__services'>HTML</span> et de feuille de style <span className='color__services'>CSS</span> personnalisée pour votre site&#160;;</li>
                <li className='presentation__lst__item'>Construire un <span className='color__services'>site Web</span> responsive et dynamique s’adaptant à tout type d’écran grâce à l’intégration des éléments des maquettes graphiques&#160;: développement front-end avec HTML, CSS, JavaScript et React&#160;; PHP&#160;; ou à partir de WordPress&#160;;</li>
                <li className='presentation__lst__item'>Créer des <span className='color__services'>API</span> et des <span className='color__services'>bases de données</span> pour développer des sites complets et dynamiques et assurer le bon fonctionnement côté serveur (développement back-end avec NodeJS, Express et MongoDB)&#160;;</li>
                <li className='presentation__lst__item'>Conseils pour améliorer le <span className='color__services'>référencement</span> de votre site&#160;;</li>
                <li className='presentation__lst__item'>Réalise des <span className='color__services'>démarches administratives</span> en ligne de manière légale et sécurisée pour le compte de personnes en difficulté avec les outils numériques&#160;;</li>
                <li className='presentation__lst__item'><span className='color__services'>Saisie</span> au kilomètre ou mise en forme de texte&#160;;</li>
                <li className='presentation__lst__item'><span className='color__services'>ePubs textuels</span> V3 et V3 avancés, reflowables et nativements accessibles&#160;;</li>
                <li className='presentation__lst__item'><span className='color__services'>Conseils et formations</span> pour améliorer vos compétences informatiques.</li>
             </ul>
            <p className='presentation__alinea blancTop'>Je suis impatiente de collaborer avec vous et de vous accompagner dans votre parcours numérique. N'hésitez pas à me contacter pour discuter de vos projets ou pour toute question. Ensemble, nous ferons de l'informatique un atout puissant pour votre réussite.</p>
            <div className='alinea__center'><Link to="../survey#contact" className='button'>Contactez-moi</Link></div>
            </div>
        </div>
    )
}

export default PresHtml