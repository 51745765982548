import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import CvPdf from './pages/CvPdf'
import Survey from './pages/Survey'
import Projets from './pages/Projets'
import ProjetHtml from './components/ProjetHtml'
import ProjetAnime from './components/ProjetAnime'
import ProjetSeo from './components/ProjetSEO'
import ProjetJavaScript from './components/ProjetJavascript'
import ProjetApi from './components/ProjetApi'
import ProjetApplication from './components/ProjetApplication'
import Error from './components/Error'
import "./styles/index.scss"
import ProjetsPro from './pages/ProjetsPro'
import Sites from './components/Sites'
import Formations from './components/Formations'
import Aide from './components/Aide'





ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/projetsPro" element={<ProjetsPro />} />
          <Route path="sites" element={<Sites />} />
          <Route path="formations" element={<Formations />} />
          <Route path="aide" element={<Aide />} />
        <Route path="/projets" element={<Projets />} />
          <Route path="html" element={<ProjetHtml />} />
          <Route path="anime" element={<ProjetAnime />} />
          <Route path="seo" element={<ProjetSeo />} />
          <Route path="javascript" element={<ProjetJavaScript />} />
          <Route path="api" element={<ProjetApi />} />
          <Route path="application" element={<ProjetApplication />} />
        <Route path="/CvPdf" element={<CvPdf />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)