import Header from "../Header"
import Footer from "../Footer"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


function ProjetHtml() {
  return (
    <div>
    <Header/>
    <main>
      <div className='Container__projet'>
          <h1 className="Container__projet__title">Transformez une maquette en site web avec <span className="times">HTML & CSS</span></h1>
          <div className="Container__projet__texte">
            <p className="Container__projet__alinea">Projet <span className="nom__Client">Booki</span> réalisé entièrement en html/css.</p>
            <p className="Container__projet__alinea">Il fallait :</p>
            <ol className="Container__projet__liste">
              <li className="Container__projet__liste__alinea">Implémenter une interface responsive, qui s'adapte à tous les formats ;</li>
              <li className="Container__projet__liste__alinea">Intégrer du contenu conformément à une maquette.</li>
            </ol>
            <p className="Container__projet__alinea">Le site est disponible sur <a href='https://fontitusse.github.io/Fontaine_Martine_2_Code_072022/' title='Booki' target='_blank' rel='noreferrer'>GitHub Pages</a>.</p>
          </div>
          <div className='alinea__center'><Link className='Projet__retour' to="../projets#openclassrooms"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link></div>
        </div>
        </main>
        <Footer/>
      </div>
  )
}

export default ProjetHtml