import Header from '../../components/Header'
import PresHtml from '../../components/Presentation';
import Footer from '../../components/Footer';


function App() {
  return (
    <div className='accueil'>
   <Header/>
   <main>
   <PresHtml/>
   </main>
   <Footer/>
   </div>
  );
}

export default App;
