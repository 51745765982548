import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'

function Aide() {
    return (
    <div>
        <Header/>
        <main>
        <div className='Projet__aide' id='sites'>
            <h1 className='Projet__aide__title'>Aide aux outils numériques</h1>
            <h2 className='Projet__aide__title2'>Connectez-vous avec vos proches&#160;: Apprenez à utiliser les réseaux sociaux pour rester en contact</h2>
            <div className='Projet__aide__alinea'>Bienvenue&#160;!</div>
            <div className='Projet__aide__alinea'>Vous vous demandez comment rester connecté avec vos proches à l'ère numérique&#160;? Ne cherchez pas plus loin&#160;! Chez Martine fait de l'informatique, je suis là pour vous guider dans l'univers des réseaux sociaux, vous permettant ainsi de partager des moments spéciaux avec vos enfants, petits-enfants et amis, où qu'ils soient dans le monde.</div>
            <h2 className='Projet__aide__title2'>Mes Services&#160;:</h2>
            <ol className='Projet__aide__liste'>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Formation Personnalisée&#160;: </span>Apprenez à créer et à gérer vos profils sur des plateformes comme Facebook, Instagram, WhatsApp, etc. Je vous montrerai comment partager des photos, des vidéos et des messages en toute sécurité.</li>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Communication Instantanée&#160;: </span>Découvrez comment utiliser les outils de messagerie pour des conversations rapides et efficaces avec vos proches, que ce soit par écrit, en appel vidéo ou en partage de photos.</li>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Partage de Moments Spéciaux&#160;: </span>Je vous guiderai pour créer des albums photo numériques, partager des souvenirs et participer à la vie numérique de votre famille.</li>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Sécurité en Ligne&#160;: </span>Je vous apprendrai à naviguer en toute sécurité sur les réseaux sociaux, à protéger votre vie privée et à éviter les risques potentiels.</li>
            </ol>
            <h2 className='Projet__aide__title2'>Pourquoi Choisir Martine fait de l'informatique&#160;?</h2>
            <ul className='Projet__aide__liste'>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Expérience conviviale&#160;: </span>Je suis là pour vous aider à votre rythme, sans jargon technique.</li>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Personnalisation&#160;: </span>Mes services sont adaptés à vos besoins spécifiques et à votre niveau de confort avec la technologie.</li>
                <li className='Projet__aide__liste__alinea'><span className='bold'>Soutien Continu&#160;: </span>Je suis toujours là pour répondre à vos questions et vous aider à surmonter les défis.</li>
            </ul>
            <div className='Projet__aide__alinea blancTop'>Connectez-vous avec vos proches d'une manière nouvelle et passionnante&#160;! Contactez-moi dès aujourd'hui pour discuter de la manière dont je peux vous aider à embrasser le monde numérique tout en restant proche de ceux qui vous sont chers.</div>
            </div>
            <div className='alinea__center'><Link to="../survey#contact" className='button'>Contactez-moi</Link></div>
        </main>            
        <Footer/>  
    </div>
    )
}

export default Aide