import Header from "../Header"
import Footer from "../Footer"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function ProjetAnime() {
  return (
    <div>
    <Header/>
    <main>
    <h1 className="Container__projet__title monpopo2">Dynamisez une page web avec des animations <span className="times">CSS</span></h1>
      <div className='Container__projet' id="monpopo2">
          <div className="Container__projet__texte">
            <p className="Container__projet__alinea">Ma mission était de développer un site "mobile first" pour notre client <span className="nom__Client">Ohmyfood</span> qui répertorie les menus de restaurants gastronomiques.</p>
            <p className="Container__projet__alinea">Il fallait :</p>
            <ol className="Container__projet__liste">
              <li className="Container__projet__liste__alinea">Développer en CSS, sans JavaScript ;</li>
              <li className="Container__projet__liste__alinea">Le code CSS disponible dans un ou plusieurs fichiers dédiés ;</li>
              <li className="Container__projet__liste__alinea">Réaliser le site en <strong>mobile first</strong>, c’est-à-dire qu’il fallait d’abord réaliser l'intégration de la maquette mobile, puis tablette, et enfin l'intégration du responsive vers le desktop ;</li>
              <li className="Container__projet__liste__alinea">Aucun framework ne devait être utilisé ; en revanche l’utilisation de SASS était un plus ;</li>
              <li className="Container__projet__liste__alinea">Tout le code devait être versionné sur GitHub avec des commits réguliers pour suivre l’avancement et publier le site en ligne plus facilement ;</li>
              <li className="Container__projet__liste__alinea">Le site devait être accessible sur <a href='https://fontitusse.github.io/Fontaine_Martine_3_code_082022/' title='Site Ohmyfood sur mon espace Github Page' target='_blank' rel='noreferrer'>GitHub Pages</a> une fois terminé.</li>
            </ol>
          </div>
          <div className='Container__book'>
            <div id='book'>
              <div id='top'></div>
              <div id='front'></div>
              <div id='right'></div>
              <div id='bottom'></div>
              <div id='shadow'></div>
              <div id='bookmark'>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='bookmark-shadow'></div>
            </div>
            <div id='flip'>
              <div id='front'>
                <div>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='back'>
                <div>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className='alinea__center'><Link className='Projet__retour' to="../projets#openclassrooms"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link></div>
        </div>
      </main>
        <Footer/>
      </div>
  )
}
  export default ProjetAnime