import { Link } from "react-router-dom"

function Error() {
    return (
        <div className="errorContainer">
            <main>
            <h1 className="errorContainer__title">Oups 🙈 Cette page n'existe pas</h1>
            <div className='alinea__center'><button className="button"><Link className="errorContainer__button__Link" to="/">Retour à la page d'Accueil</Link></button></div>
            </main>
        </div>
    )
}
 
export default Error