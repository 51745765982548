import Header from "../Header"
import Footer from "../Footer"
import Slider from "../Slider"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import rapportPicture6 from '../../assets/rapport_optimisation_1.webp'
import rapportPicture7 from '../../assets/rapport_optimisation_2.webp'
import rapportPicture8 from '../../assets/rapport_optimisation_3.webp'
import rapportPicture9 from '../../assets/rapport_optimisation_4.webp'
import rapportPicture10 from '../../assets/rapport_optimisation_5.webp'
import rapportPicture11 from '../../assets/rapport_optimisation_6.webp'

 

function ProjetSeo() {
  const datas = [
    {
        id:6,
        image: `${rapportPicture6}`,
    },
    {
        id:7,
        image: `${rapportPicture7}`,
    },
    {
        id:8,
        image: `${rapportPicture8}`,
    },
    {
        id:9,
        image: `${rapportPicture9}`,
    },
    {
        id:10,
        image: `${rapportPicture10}`,
    },
    {
      id:11,
      image: `${rapportPicture11}`,
  },
]
  return (
    <div>
    <Header/>
    <main>
      <div className='Container__projet'>
        <h1 className="Container__projet__title">Optimisez un site web existant</h1>
          <div className="Container__projet__texte">
            <p className="Container__projet__alinea">Projet d'amélioration du référencement du site <span className="nom__Client">La Panthère</span>.</p>
            <p className="Container__projet__alinea">En effet, le site n’apparaissait qu’en deuxième page des moteurs de recherche.</p>
            <p className="Container__projet__alinea">Il fallait :</p>
            <ol className="Container__projet__liste">
              <li className="Container__projet__liste__alinea">Analyser l’état actuel du SEO du site fourni et livrer un rapport d'analyse au format pdf ;</li>
              <li className="Container__projet__liste__alinea">Améliorer le SEO du site ;</li>
              <li className="Container__projet__liste__alinea">Comparer les résultats de recherche avant/après mes améliorations.</li>
            </ol>
            <p className="Container__projet__alinea">Le site est disponible sur <a href='https://fontitusse.github.io/Fontaine_Martine_4_code_092022/' title='La Panthère' target='_blank' rel='noreferrer'>GitHub Pages</a>.</p>
            <h2 className="Container__projet__stitle">Rapport d'analyse :</h2>
              <div className="App">
              <Slider />
              </div> 
            <div className="rapport__opt">
            <h2 className="Container__projet__stitle">Rapport d'optimisation :</h2>
              <div className="center">  {datas.map(slide => (
                  <div key={slide.id}>
                   <img src={slide.image} alt="Rapport d'optimisation" width="60%" height="auto"/>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='alinea__center'><Link className='Projet__retour' to="../projets#openclassrooms"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link></div>
        </div>
        </main>
        <Footer/>
      </div>
  )
}

export default ProjetSeo