import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'



 
function Footer() {
    return (
        <footer>
        <div className='footer'>
            <div className='footer__presentation'>
                <div className="footer__titre">MARTINE FAIT DE&#160;L'INFORMATIQUE</div> 
                <div className='footer__stitre'>Développeuse Intégratrice&#160;WEB</div>
                <div className='footer__copyr'>Ce site a été réalisé entièrement avec React.js par : <a href="mailto:martinefaitdelinformatique@gmail.com" target='_blanck' rel='noreferrer'>@martinefaitdelinformatique</a>.</div>
                <div className='footer__CvPdf'><Link className='footer__navbar__link' to="/CvPdf">Télécharger mon CV</Link></div>
                <div className='footer__raiseaux'><a href='https://www.linkedin.com/in/martine-fait-de-l-informatique-b029ab2a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' title='Mon Compte Linkedin' className='IconeLinkedin' target='_blanck'><FontAwesomeIcon icon={faLinkedin}/></a></div>
 
 </div>
        <nav className='footer__navbar'>
            <Link className='footer__navbar__link' to="/"><FontAwesomeIcon icon={faArrowRight} bounce /> Accueil</Link>
            <Link className='footer__navbar__link' to="/projetsPro"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets professionnels</Link>
            <Link className='footer__navbar__link' to="/projets"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link>
            <Link className='footer__navbar__link' to="/survey"><FontAwesomeIcon icon={faArrowRight} bounce /> Contact</Link>
        </nav>
        </div>
        </footer>
    )
}

export default Footer