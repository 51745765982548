import Header from '../../components/Header'
import Footer from '../../components/Footer'

function Formations() {
    return (
    <div>
        <Header/>
        <main>
        <div className='Projet__formations' id='formations'>
        <h1 className='Projet__formations__title'>Les Formations</h1>
        <h2 className='Projet__formations__title2'>Libérez Votre Créativité en Ligne&#160;: Créez Votre Site Web Facilement avec Martine fait de l'informatique !</h2>
        <div className='Projet__formations__alinea'>Bienvenue chez Martine fait de l'informatique, votre partenaire pour concrétiser vos idées en ligne. Si vous rêvez de posséder votre propre site web, mais que la technologie vous semble intimidante, ne vous inquiétez plus&#160;! Je suis là pour simplifier le processus et vous guider à chaque étape du chemin.</div>
        <h2 className='Projet__formations__title2'>Comment je peux vous aider&#160;:</h2>
        <ol className='Projet__formations__liste'>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Installation et Configuration&#160;: </span>Ne vous préoccupez pas des détails techniques&#160;! Je prendrai en charge l'installation du CMS sur votre hébergement et configurerai les paramètres pour démarrer du bon pied.</li>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Conception Personnalisée&#160;: </span>Votre site doit refléter votre identité. Je vous montrerai comment personnaliser l'apparence de votre site avec des thèmes et des modèles adaptés à votre style.</li>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Ajout de Contenu&#160;: </span>Apprenez à ajouter et à gérer du contenu de manière simple. Que ce soit des articles de blog, des images, des vidéos ou des pages, vous serez en mesure de mettre à jour votre site en toute autonomie.</li>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Formation Personnalisée&#160;: </span>Je vous guiderai à travers des sessions de formation personnalisées. Je répondrai à vos questions et vous donnerai les compétences nécessaires pour être autonome dans la gestion de votre site.</li>
        </ol>
        <h2 className='Projet__formations__title2'>Pourquoi Martine fait de l'informatique&#160;?</h2>
        <ol className='Projet__formations__liste'>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Expérience Conviviale&#160;: </span>Je m'engage à rendre la création de sites web accessible à tous, quel que soit votre niveau de compétence technique.</li>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Soutien Continu&#160;: </span>Je suis disponible pour répondre à vos questions même après la création de votre site. Vous n'êtes jamais seul.</li>
            <li className='Projet__formations__liste__alinea'><span className='bold'>Résultats Professionnels&#160;: </span>Je vise des sites web non seulement faciles à gérer, mais aussi esthétiquement plaisants et professionnels.</li>
        </ol>
        <div className='Projet__formations__alinea blancTop'>Lancez-vous dans le monde en ligne dès aujourd'hui&#160;! Contactez Martine fait de l'informatique pour discuter de vos idées et commencer à construire votre présence en ligne.</div>
        <div className='alinea__center'><a href="../survey#contact" className='button'>Contactez-moi</a></div>
        <h2 className='Projet__formations__title2 blancTitleTop'>Formation WordPress donnée à Mathilde pour son site <a href='https://maisonmaiarchitecture.fr' title="Maison Mai Architecture à Orléans" target='_blank' rel='noreferrer'>Maison Mai Architecture à Orléans</a></h2>
            <div className='Projet__formations__container__remer center'>&laquo; Un énorme merci 🙏🙏🙏🙏 &raquo;</div>
            <div className='Projet__formations__container__remer right'>Mathilde</div>
        <h2 className='Projet__formations__title2'>Formation et maintenance WordPress donnée à Laurent et à Christian pour leur site <a href='https://asrabajou.fr' title="AS Rabajou - Trails de Rabajou et de Chamerolles" target='_blank' rel='noreferrer'>AS Rabajou - Trails de Rabajou et de Chamerolles</a></h2>
            <div className='Projet__formations__container__remer center'>&laquo; Merci de la part de Laurent et moi pour la formation et pour le résultat obtenu malgré un auditoire un peu dissipé... 😎 On sent que tu es passionnée. &raquo;</div>
            <div className='Projet__formations__container__remer right'>Christian et Laurent</div>
        </div>
        </main>
        <Footer/>
    </div>
    )
}

export default Formations