import SliderPlan from "../SliderPlan"
import Header from "../Header"
import Footer from "../Footer"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function ProjetJavaScript() {
    return (
        <div>
        <Header/>
        <main>
        <div className="Container__projet">
        <h1 className="Container__projet__title">Construisez un site e-commerce en JavaScript</h1>
        <p className="Container__projet__alinea">Mon client est <span className="nom__client">Kanap</span>, une marque de canapés qui vend ses produits depuis sa boutique exclusivement. Aujourd’hui, celle-ci souhaiterait avoir une plateforme de e-commerce en plus de sa boutique physique pour vendre ses produits sur Internet.</p>
        <p className="Container__projet__alinea">Il fallait&#160;:</p>
        <ol className="Container__projet__liste">
            <li className="Container__projet__liste__alinea">Unifier les travaux déjà réalisés par l’équipe et disponibles sur Github en intégrant dynamiquement les éléments de l’API dans les différentes pages web avec JavaScript ;</li>
            <li className="Container__projet__liste__alinea">Mettre en place un plan de test d’acceptation.</li>
        </ol>
        <p className="Container__projet__alinea">L’application web était composée de 4&#160;pages&#160;:</p>
            <ul className="Container__projet__liste">
            <li className="Container__projet__liste__alinea">Une page d’accueil montrant (de manière dynamique) tous les articles disponibles à
            la vente.</li>
            <li className="Container__projet__liste__alinea">Une page “produit” qui affiche (de manière dynamique) les détails du produit sur
            lequel l'utilisateur a cliqué depuis la page d’accueil. Depuis cette page, l’utilisateur
            peut sélectionner une quantité, une couleur, et ajouter le produit à son panier.</li>
            <li className="Container__projet__liste__alinea">Une page “panier”. Celle-ci contient plusieurs parties&#160;:
                <ul className="Container__projet__sliste">
                <li className="Container__projet__sliste__alinea">Un résumé des produits dans le panier, le prix total et la possibilité de
                    modifier la quantité d’un produit sélectionné ou bien de supprimer celui-ci.</li>
                <li className="Container__projet__sliste__alinea">Un formulaire permettant de passer une commande. Les données du
                    formulaire doivent être correctes et bien formatées avant d'être renvoyées au
                    back-end. Par exemple, pas de chiffre dans un champ prénom.</li>
                </ul>
            </li>
            <li className="Container__projet__liste__alinea">Une page “confirmation”&#160;:
                <ul className="Container__projet__sliste">
                <li className="Container__projet__sliste__alinea">Un message de confirmation de commande, remerciant l'utilisateur pour sa
                    commande, et indiquant l'identifiant de commande envoyé par l’API.</li>
                </ul>
            </li>
            </ul>
            <h2 className="Container__projet__stitle">Code avec intégration dynamique des éléments de l'API dans les pages web avec JavaScript</h2>
            <p className="Container__projet__alinea"><a href="https://github.com/Fontitusse/Projet_5_Kanap.git" target="_blank" rel="noreferrer">Mon code est disponible sur Github</a>.</p>
            <h2 className="Container__projet__stitle">Plan de test d'acceptation&#160;:</h2>
            <div className="App">
                <SliderPlan/> 
            </div>
            <div className='alinea__center'><Link className='Projet__retour' to="../projets#openclassrooms"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link></div>
        </div>
        </main>
        <Footer/>
    </div> 
        )}

 export default ProjetJavaScript   

