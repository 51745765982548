import Header from "../Header"
import Footer from "../Footer"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function ProjetApi() {
    return (
      <div>
      <Header/>
      <main>
        <div className='Container__projet'>
            <h1 className="Container__projet__title">Construisez une <span className="times">API</span> sécurisée pour une application d'avis gastronomiques</h1>
            <div className="Container__projet__texte">
              <p className="Container__projet__alinea">Un nouveau client <span className="nom__Client">Piiquante</span>, la marque de condiments à base de piment Piiquante, veut développer une application web de critique des sauces piquantes appelée « Hot Takes ».</p>
              <p className="Container__projet__alinea">La responsable produit de Piiquante souhaite à terme transformer l'application d'évaluation en une boutique en ligne, elle souhaite que la première version soit une « galerie de sauces » permettant aux utilisateurs de télécharger leurs sauces piquantes préférées et de liker ou disliker les sauces que d'autres partagent.</p>
              <p className="Container__projet__alinea">Le front-end de l'application a été développé à l'aide d'Angular et a été précompilé après des tests internes, mais Piiquante a besoin d'un développeur back-end pour construire l'API.</p>
              <p className="Container__projet__alinea">Le code était disponible sur ce Github et il fallait suivre les spécifications techniques données par le client.</p>          
              <p className="Container__projet__alinea">Il fallait :</p>
                <ol className="Container__projet__liste">
                  <li className="Container__projet__liste__alinea">Mettre en œuvre des opérations CRUD de manière sécurisée ;</li>
                  <li className="Container__projet__liste__alinea">Stocker des données de manière sécurisée ;</li>
                  <li className="Container__projet__liste__alinea">Implémenter un modèle logique de données conformément à la réglementation.</li>
                </ol>
              <p className="Container__projet__alinea">Le code est disponible sur <a href='https://github.com/Fontitusse/Projet_6_HotTakes.git' title='Booki' target='_blank' rel='noreferrer'>GitHub</a>.</p>
            </div>
            <div className='alinea__center'><Link className='Projet__retour' to="../projets#openclassrooms"><FontAwesomeIcon icon={faArrowRight} bounce /> Projets Openclassrooms</Link></div>
        </div>
      </main>
        <Footer/>
        </div>
    )
  }
  
  export default ProjetApi